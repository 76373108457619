import React from 'react';


const RegisterApp = () => {
  return (
    <div>
        Registered.
    </div>
  );
}

export default RegisterApp;
