import React from 'react';

import './DocumentNotFound.css';

const DocumentNotFound = () => {
  return (
    <div className="DocumentNotFound">
        <h1>CooperSurgical In-Service
            <br/>
            Document Library
        </h1>
        <p>
            Document Not Found.
        </p>
    </div>
  );
}

export default DocumentNotFound;
