import React from 'react';

import {Switch,Route} from 'react-router-dom';

import RegisterApp from './components/RegisterApp/RegisterApp';
import DocumentNotFound from './components/DocumentNotFound/DocumentNotFound';

import './App.css';

function App() {
  return (
    <div>
       <Switch>
           <Route path="/register" exact><RegisterApp/></Route>
           <Route><DocumentNotFound/></Route>

       </Switch>
    </div>
  );
}

export default App;
